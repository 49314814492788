<template>
  <div class="grey lighten-3" style="height:calc(100% - 50px);overflow:auto">
    <v-card class="ma-3" color="transparent" flat>
      <v-sheet
        color="primary"
        elevation="6"
        class="px-4 py-2 mt-8 mx-2 mb-2 v-card-material rounded"
        dark
      >
        <div class="v-card-material-title pt-1">
          医生关怀
        </div>
        <div class="v-card-material-subtitle py-1">
          腹透远程系统
        </div>
      </v-sheet>
      <v-card
        elevation="6"
        class="mt-4 pa-4 details mx-2"
        v-if="history.length === 0"
      >
        <div class="primary--text text-center">暂无数据</div>
      </v-card>
      <v-card
        v-for="(item, index) in history"
        :key="index"
        elevation="6"
        class="mt-4 pa-4 details mx-2"
      >
        <div class="primary--text">医生：{{ item.CREATER_NAME }}</div>
        <div>建议：{{ item.PROBLEM }}</div>
        <div v-if="item.CBZ">备注：{{ item.CBZ }}</div>
        <div v-if="item.CTIME">回答时间：{{ item.CTIME }}</div>
      </v-card>
    </v-card>
    <PreCheck />
  </div>
</template>
<script>
import { mapState } from "vuex";
import PreCheck from "@/components/Local/PreCheck.vue";
export default {
  name: "App",
  components: { PreCheck },
  data: () => ({
    msg: "DoctorCare",
    loading: false,
    history: [],
  }),
  computed: {
    ...mapState(["currentUser"]),
  },
  created() {
    this.postGet_SCare();
  },
  methods: {
    postGet_SCare() {
      this.loading = true;
      this.$api
        .postGet_SCare({
          IDCARD: this.currentUser.IDCARD,
        })
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            let history = JSON.parse(data.data || "[]");
            this.history = history;
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.details {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.14rem;
}
</style>
